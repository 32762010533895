<template>
  <div
    class="modal modal__right"
    tabindex=""
    role="dialog"
    aria-labelledby="modalLabel"
    aria-hidden="true"
    :class="{ show: show === true }"
    :style="{ display: 'block' }"
    v-if="show"
  >
    <div class="modal__dialog" role="document">
      <transition name="slide" appear>
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon">
              <a
                class="modal__close modal__close--black"
                @click="$emit('close')"
              >
                <ion-icon name="arrow-back-outline"></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">
              Begin Maturity Period
            </h5>
          </div>
          <div class="modal__body">
            <form>
              <div class="form__item">
                <label class="form__label">Maturity Date</label>
                <input
                  type="date"
                  class="input form__input form__input--lg"
                  v-model="maturity.maturityDate"
                />
                <span
                  >min:{{ accountDetails.minimumTermLenght }} max:{{
                    accountDetails.maximumTermLenght
                  }}</span
                >
              </div>
              <div class="form__item">
                <div class="form__item">
                  <label
                    class="form__label text--regular-alt text--bold text--black"
                    >notes</label
                  >
                  <textarea
                    cols="5"
                    rows="5"
                    class="input form__input"
                    placeholder=""
                    v-model="maturity.comment"
                  ></textarea>
                </div>
              </div>

              <div class="form__item">
                <div class="form__action">
                  <button
                    data-dismiss="#confirmModal"
                    class="button form__button form__button--lg save-changes"
                    v-if="loading"
                  >
                    <div class="spinner-border texxt-light" role="status"></div>
                  </button>
                  <button
                    v-else
                    type="button"
                    class="button form__button form__button--lg save-changes"
                    @click.prevent="beginMaturity"
                  >
                    Begin Maturity Period
                  </button>
                  <a
                    data-dismiss="#accruedInterestModal"
                    role="button"
                    class="
                      modal__close
                      form__action__text form__action__text--danger
                      text--capital
                      ml6
                    "
                    @click="$emit('close')"
                  >
                    cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import ApiService from "@/core/services/api.service";
export default {
  name: "beginMaturity",
  props: {
    show: Boolean,
    close: Function,
    accountDetails: [Object, Array],
  },
  data() {
    return {
      loading: false,
      maturity: {
        savingAccountKey: this.$route.params.id,
        maturityDate: "",
        comment: "",
      },
    };
  },
  methods: {
    beginMaturity() {
      this.loading = true;
      ApiService.post(
        "DepositAccount/addmaturityperiod",
        this.maturity
      )
        .then((response) => {
          if (response.data.status !== false) {
            this.loading = false;
            this.$emit("add-maturity-modal", response.data.message);
          } else {
            this.loading = false;
            this.$emit("error-maturity-modal", response.data.message);
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$emit("error-maturity-modal", error.response.data.message);
        });
    },
  },
};
</script>
